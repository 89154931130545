import React from "react";
import {
  FunnelDataType,
  FunnelBackendDataType,
} from "@components/funnel/builder/FunnelContext";
import { RatingTemplatesTypes } from "@components/funnel/ratingType/RatingTypeTemplates";
import { randomBytes } from "crypto";
import ReactDOMServer from "react-dom/server";
import ReactHtmlParser from "react-html-parser";

declare global {
  interface Window {
    ahoy?: any;
    pendo?: any;
  }
}

export const trackAhoyEvent = ({ target }) => {
  window.ahoy.track(target.getAttribute("data-track-name"), {
    key: target.getAttribute("data-track-key"),
    text: target.getAttribute("data-track-text"),
    destination: target.getAttribute("data-track-destination"),
    context: target.getAttribute("data-track-context"),
    group: target.getAttribute("data-track-group"),
    sort_order: target.getAttribute("data-track-sort-order"),
    sentiment: target.getAttribute("data-track-sentiment"),
    rating: target.getAttribute("data-track-rating"),
  });
};

export const isEditMode = (context: FunnelDataType): boolean => {
  return !context.frontend.previewMode && !context.frontend.presentMode;
};

export const ratingTitle = (funnelData) => {
  switch (funnelData.backend.site_attributes.theme_template) {
    case RatingTemplatesTypes.STARS:
      return starsTitle(funnelData);
    case RatingTemplatesTypes.THUMBS:
      return thumbsTitle(funnelData);
    case RatingTemplatesTypes.NPS:
      return npsTitle(funnelData);
    default:
      return starsTitle(funnelData);
  }
};

const starsTitle = (funnelData) => {
  let rating = (
    funnelData.backend.config_modules_attributes.segmenting_threshold / 2
  ).toString();
  if (funnelData.funnelFlow.selectedRatingString)
    rating = funnelData.funnelFlow.selectedRatingString.split("_")[1];

  return `You rated ${rating} out of 5 stars`;
};

const thumbsTitle = (funnelData) => {
  let rating = "thumbs down";
  if (funnelData.funnelFlow.selectedRatingString == "binary_choice_positive")
    rating = "thumbs up";

  return `You rated ${rating}`;
};

const npsTitle = (funnelData) => {
  let rating = (
    funnelData.backend.config_modules_attributes.segmenting_threshold / 2
  ).toString();
  if (funnelData.funnelFlow.selectedRatingString)
    rating = funnelData.funnelFlow.selectedRatingString.split("_")[1];

  return `You rated ${rating} out of 10`;
};

export const quillFonts = {
  serif: {
    label: "Serif",
    family: "Georgia, Times New Roman, serif",
  },
  monospace: {
    label: "Monospace",
    family: "Monaco, Courier New, monospace",
  },
};

export const quillFontSizes = {
  small: {
    label: "Small",
    size: "70%",
  },
  large: {
    label: "Large",
    size: "120%",
  },
  huge: {
    label: "Huge",
    size: "150%",
  },
};

export const generateRequestId = (prefix: string) => {
  return `${prefix}_${randomBytes(16).toString("hex")}`;
};

export const usingCustomDomain = (
  funnelDataBackend: FunnelBackendDataType
): boolean => {
  return (
    funnelDataBackend.site_attributes.domain &&
    funnelDataBackend.site_attributes.domain_status != "none"
  );
};

interface RichTextContentArgs {
  template: string;
  value: string;
}

export const renderTitleRichTextContent = ({
  template,
  value,
}: RichTextContentArgs): string => {
  const parsedTemplate = template || "<p class=\"ql-align-center\"><strong>Default text</strong></p>";
  return ReactDOMServer.renderToStaticMarkup(
    ReactHtmlParser(parsedTemplate, {
      transform: (node) => {
        // do not render any <span> tags
        if (node.children === undefined && node.type === "text") {
          return <>{value}</>;
        }
      },
    })
  );
};
