import React, {
  FunctionComponent as FC,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  FunnelContext,
  FunnelBackendDataType,
} from "@components/funnel/builder/FunnelContext";

import P from "@atoms/Typography/Text";
import RoundCheckbox from "@atoms/RoundCheckbox";
import Radio from "@atoms/Radio";
import Checkbox from "@atoms/Checkbox";
import Input from "@molecules/Input";

import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { useMutation } from "react-query";

import {
  ImutationParams,
  ISiteParams,
  updateSite,
} from "@components/funnel/hooks/useSiteUpdate";
import { usingCustomDomain } from "@utils/funnel";

import { $SettingCard } from "@molecules/SettingCard/SettingCard.sc";
import {
  $CustomDomainContainer,
  $DomainToggleContainer,
  $Form,
  $RadioOptionsContainer,
  $DomainInputContainer,
} from "./CustomDomain.sc";

const CustomDomain: FC = () => {
  const { funnelData, updateFrontend } = useContext(FunnelContext);
  const [editing, setEditing] = useState(usingCustomDomain(funnelData.backend));
  const [domainStatus, setDomainStatus] = useState(
    funnelData.backend.site_attributes.domain_status
  );
  const alert = useAlert();

  // TODO change
  const pendoGuideId = "znAnaSpGd_NvTQHZ-x7ALyub2Nc";

  useEffect(() => {
    if (
      domainStatus == "own" &&
      !funnelData.backend.site_attributes.domain &&
      window.pendo?.findGuideById
    ) {
      window.pendo.findGuideById(pendoGuideId)?.show();
    }
  }, [domainStatus]);

  const { handleSubmit, register, getValues, errors } = useForm({
    mode: "onChange",
  });

  const mutation = useMutation((params: ImutationParams) => updateSite(params));

  const submitSiteUpdates = async (params: ISiteParams) => {
    mutation.mutate(
      {
        site: params,
        id: funnelData.backend.site_attributes.id,
        site_uuid: funnelData.backend.site_attributes.uuid,
        page_id: funnelData.backend.page_attributes.id,
      },
      {
        onSuccess: (responseData: FunnelBackendDataType) => {
          setEditing(usingCustomDomain(responseData));
          funnelData.backend = responseData;
          updateFrontend();
          alert.success(<p>Custom domain setting was saved.</p>);
        },
        onError: (error: any) => {
          //@ts-ignore
          const errorMessage = error.response.data["Domain"]
            ? error.response.data["Domain"].join(", ")
            : "Please try again later.";

          alert.error(
            <>
              <h3>Something went wrong.</h3>
              <p>{errorMessage}</p>
            </>
          );
        },
      }
    );
  };

  const toggleCustomDomain = (e) => {
    setEditing(e.target.checked);

    if (e.target.checked) {
      setDomainStatus("own");
    } else {
      submitSiteUpdates({
        domain_status: "none",
        domain: funnelData.backend.site_attributes.domain,
        domain_is_shared: funnelData.backend.site_attributes.domain_is_shared,
      });
    }
  };

  const handleShareDomain = (e) => {
    submitSiteUpdates({
      domain_status: getValues("domain_status"),
      domain: getValues("domain"),
      domain_is_shared: e.target.checked,
    });
  };

  return (
    <$SettingCard>
      <$CustomDomainContainer>
        <$DomainToggleContainer>
          <P size="3" color="baseDark" fontWeight={600}>
            CUSTOM DOMAIN
          </P>
          <RoundCheckbox checked={editing} onChange={toggleCustomDomain} />
        </$DomainToggleContainer>

        {editing ? (
          <$Form
            onSubmit={handleSubmit(submitSiteUpdates)}
            className="clear-float"
          >
            <$RadioOptionsContainer>
              {[
                ["own", "Domain"],
                ["cname", "Subdomain"],
              ].map(([domainStatusValue, domainStatusLabel], index) => {
                return (
                  <Radio
                    id={`site_domain_status_${domainStatusValue}`}
                    key={`custom-domain-radio-${domainStatusValue}`}
                    testId={`custom-domain-radio-${domainStatusValue}`}
                    name="domain_status"
                    value={domainStatusValue}
                    register={register()}
                    label={domainStatusLabel}
                    defaultChecked={domainStatusValue == domainStatus}
                    onClick={() => setDomainStatus(domainStatusValue)}
                    size="16"
                    labelSize="16"
                    styling={{
                      float: "left",
                      marginRight: index == 0 ? "20px" : "0px",
                    }}
                  />
                );
              })}
            </$RadioOptionsContainer>
            <$DomainInputContainer>
              <Input
                name="domain"
                register={register({
                  pattern: {
                    value: new RegExp(
                      `^([A-Za-z0-9-_]+\.)${
                        domainStatus == "cname" ? "+" : "{0,1}"
                      }[A-Za-z0-9-_]+\.[A-Za-z0-9-_]{2,}$`
                    ),
                    message: "Invalid domain/subdomain.",
                  },
                })}
                errors={errors.domain}
                defaultValue={funnelData.backend.site_attributes.domain}
                onBlur={handleSubmit(submitSiteUpdates)}
              />
            </$DomainInputContainer>
            <Checkbox
              id="site_domain_is_shared"
              name="domain_is_shared"
              testId={`custom-domain-is-shared`}
              register={register()}
              label="Shared by multiple businesses"
              defaultChecked={
                funnelData.backend.site_attributes.domain_is_shared
              }
              onChange={handleShareDomain}
            />
          </$Form>
        ) : (
          <div className="clear-float">
            {funnelData.backend.site_attributes.domain &&
              funnelData.backend.site_attributes.domain_status != "none" && (
                <P size="2" color="baseDarkest">
                  {funnelData.backend.site_attributes.domain}
                </P>
              )}
          </div>
        )}
      </$CustomDomainContainer>
    </$SettingCard>
  );
};

export default CustomDomain;
